import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout.js"
import { Section } from "../../components/sections/sections.js"
import {
  ValuePropLeft,
  ValuePropRight,
} from "../../components/sections/valuePropSection.js"

import {
  SectionTitle,
  SectionDescription,
} from "../../components/typography.js"

import ScrumSvg from "../../images/Scrum_board.svg"
import Coding from "../../images/hacker_mind.svg"
import Social from "../../images/social_networking.svg"
const St = styled(SectionTitle)`
  width: 90%;
  margin: 0 auto;
  font-size: 70px;
`
const Sd = styled(SectionDescription)`
  margin: 50px auto;
  width: 90%;
  font-size: 22px;
  text-align: center;
`
const Span = styled.span`
  color: #f50057;
`
const About = () => {
  return (
    <Layout>
      <Section>
        <St center={"center"}>
          <Span>Work</Span> your butt off, <Span>redefine</Span> your ambitions,{" "}
          <Span>achieve</Span> your goals, and make your career-path{" "}
          <Span>simpler</Span>.
        </St>

        <Sd>
          At <Span>99point9</Span>, we learn to empower ourselves: For us, test
          scores and assessments are only an indication of how well we've
          managed this. We aim for perfection, we work hard to achieve it, and
          we don't give up. We look inwards first, analyzing and correcting our
          biases, and our learning habits; We strive to redefine our boundaries
          and push our comfort zones. Via this, thousands of students who have
          worked with us and have adopted our philosophy, have found that they
          more capable than they ever imagined.
        </Sd>
      </Section>

      <ValuePropLeft
        caption={" "}
        title={[`Prioritizing `, <Span>Content</Span>]}
        description={`We believe that good content must be more than just "engaging": It has to be bottom-up, self-explanatory, challenging, and thorough. Our unique approach is to content-creation cenetered around logic-promotion and concept-connection.`}
        source={ScrumSvg}
      />
      <ValuePropLeft
        caption={" "}
        title={[`Prioritizing `, <Span>Tech.</Span>]}
        description={
          "Our interdisciplinary Education-Technology focus is unparalleled: We firmly believe that the future of education -- i.e. helping students learn better -- lies in tools specifically made for this purpose. That's why our core-instructors are required to learn coding, so they can leverage the use principles of programming in content-creation, and principles of teaching to create engaging tutorials and assesments."
        }
        source={Coding}
      />
      <ValuePropLeft
        caption={" "}
        title={[`Prioritizing `, <Span>Students</Span>]}
        description={`Our unparalleled student-first approach permeates every aspect of our operations. Specifically, our instructors at times speak to students around the clock, and make themselves available when our students are studying: We've taken calls in the middle of the night, gotten to know our students personally, and help them beyond the scope of our "services". `}
        source={Social}
      />
    </Layout>
  )
}

export default About
